details {

    summary {
        background-color: var(--gray-200);
        transition: all 0.3s ease;


        h4 {
            color: var(--gray-500);
        }

        &::after {
            content: url("/assets/images/icons/icon-angle-down-fill.svg");
            display: block;
            width: 24px;
            height: 24px;

        }
    }
}

details[open] {
    &>summary::after {
        content: url("/assets/images/icons/icon-angle-up-fill.svg");
    }

    &>summary {
        background-color: var(--gray-900);
        transition: all 0.3s ease;

        h4 {
            color: white;
        }
    }
}